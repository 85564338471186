


#contact_page {

	position: relative;
	margin-top: $innerNavHeight * 1.6;


	#contact_bg {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #ffffff url(/img/bg/bridge-backgrounds-3b.jpg) no-repeat center center;
		background-size: cover;
		z-index: -1;
	}

	#contact_main_column {
		//overflow: auto;
		//display: inline-block;
		//border: 1px #0000ff solid;
		position: relative;
		margin: 0 auto;
		max-width: 1000px;
		font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
		letter-spacing: 0.02em;



		h1 {
			font: 900 2.3em 'Open Sans', sans-serif;
			margin: 5em 0 1em 0;
		}

		#contact_intro {
			margin: 0 0 4em;
		}

		form {

			input {
				outline: none;
				padding: 0.3em 0.5em;
				font: 400 1.0em 'Open Sans', sans-serif;
				margin: 0 0 1em 0;
				border: 1px #aaa solid;

				transition: box-shadow 1500ms;
			}

			textarea {

				width: 30em;
				height: 12em;

				outline: none;
				padding: 0.3em 0.5em;
				font: 400 1.0em 'Open Sans', sans-serif;
				margin: 0 0 1em 0;
				border: 1px #aaa solid;

				transition: box-shadow 1500ms;
			}

			input:focus,
			textarea:focus {
				box-shadow: rgba(#ffff66, 0.6) 0 0 50px;
			}


			button {
				margin: 0 auto;
				padding: 0.5em 1.8em;
				font: 400 0.9em 'Open Sans', sans-serif;
				cursor: pointer;

				transition: box-shadow 1500ms;
			}

			button:focus,
			button:hover {
				box-shadow: rgba(#ffff66, 0.6) 0 0 50px;
			}

		}

	}

}





//******************************************************************************************
//******************************************************************************************

@media (max-width: $smallMobileWidth) {

	#contact_page {

		#contact_main_column {
			max-width: 90%;

			h1 {
				margin-top: 2em;
			}

			form {


				input {
					width: 90%;
				}

				textarea {
					width: 90%;
				}
			}
		}

	}
}



