#investors_page {

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	#investors_bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		background: #ffffff url(/img/bg/bridge-backgrounds-4.jpg) no-repeat 20% 50%;
		background-size: cover;
	}

	#investors_bg_files {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		padding: $innerNavHeight * 2.4 3em 3em;
		text-align: left;

		background: rgba(#ffffff, 0.8);
		opacity: 0;

		transition: opacity 2000ms;


		h1 {
			font: 900 2.3em 'Open Sans', sans-serif;
			color: #000000;
			margin: 0 0 0.5em 1em;
		}

		.investors_file {
			display: block;
			float: left;
			position: relative;
			text-align: center;
			font: 400 1.0em 'Open Sans', sans-serif;
			margin: 1em 2em;
			padding: 0.5em;
			cursor: pointer;
			white-space: nowrap;
			text-decoration: none;
			color: #000000;

			transition: background 500ms, box-shadow 500ms;


			img {
				width: 6em;
			}
		}

		.investors_file:hover {
			background: rgba(#ffff66,0.3);
			box-shadow: rgba(#ffff66, 0.3) 0 0 50px;
		}

	}

	#investors_bg_files.show {
		opacity: 1;
	}




	form#investors_form {
		position: absolute;
		z-index: 20;

		top: 70%;
		left: 72%;
		//text-align: center;
		text-align: left;
		transform: translate(-50%,-50%);
		transition: opacity 1000ms;


		h1 {
			font: 900 2.3em 'Open Sans', sans-serif;
			color: #ffffff;
			margin: 0 0 0.5em;
		}

		input {
			outline: none;
			padding: 0.3em 0.5em;
			font: 400 1.0em 'Open Sans', sans-serif;
			margin: 0 0 1em 0;

			transition: box-shadow 1500ms;
		}

		input:focus {
			box-shadow: rgba(#ffff66, 0.6) 0 0 50px;
		}

		button {
			margin: 0 auto;
			padding: 0.5em 1.8em;
			font: 400 0.9em 'Open Sans', sans-serif;
			cursor: pointer;

			transition: box-shadow 1500ms;
		}

		button:focus,
		button:hover {
			box-shadow: rgba(#ffff66, 0.6) 0 0 50px;
		}

	}

	form.hide {
		opacity: 0;
	}
}




#app_page.page_app_investors #app_footer {
	display: none;
}






//******************************************************************************************
//******************************************************************************************



@media (max-width: $smallMobileWidth) {
	#investors_page {

		form#investors_form {

			top: 50%;
			left: 50%;
		}


		#investors_bg_files {
			padding: 2em;

			h1 {
				margin: 0 0 0.5em 0;
			}

			.investors_file {
				float: none;
				font-size: 0.7em;
				width: 100%;
				margin: 0 auto;

				img {
					width: 15vw;
				}
			}

		}

	}
}
