

body {
	background: #ffffff;
	margin: 0;
	//overflow: hidden;
	overflow-y: scroll;
}


#app_page {
	position: relative;
	top: -1px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	border-top: 1px transparent solid;
	//border: 20px #0000ff solid;


	i#app_menu_open_icon,
	i#app_menu_close_icon {
		display: none;
	}

	#app_nav {
		//position: relative;
		position: fixed;
		margin: 0;
		padding: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: $innerNavHeight;
		background-color: #313A43;
		text-align: center;
		z-index: 100;

		opacity: 1;
		//opacity: 0.5;

		transition: opacity 1000ms;
	}

	#app_nav.hide {
		opacity: 0;
		transition: opacity 0ms;
	}

	ul#app_nav_items {
		display: inline-block;
		margin: 0 0 0 -3em;
		padding: 0;
		list-style: none;
		height: 100%;
		white-space: nowrap;
		overflow: hidden;

		img {
			margin: -0.3em 0.6em 0 0;
			padding: 0;
			height: 2em;
			float: left;
			//visibility: hidden;
			opacity: 0;
			transition: opacity 1500ms;
		}

		li {
			display: inline-block;
			margin: 0 0 0 0.3em;
			padding: 0.6em 1em 0 0.7em;
			float: left;
			color: #ffffff;
			font: 300 0.9em 'Open Sans', sans-serif;
			letter-spacing: 0.07em;
			height: 100%;
			transition: background 1500ms;
		}
		li:first-child {
			margin-left: 0;
		}
		li:hover {
			background: #005da4;
			color: #ffffff;
			//background: #eeeeee;
			//color: #005da4;
			cursor: pointer;
			transition: background 250ms;

			img {
				//visibility: visible;
				opacity: 1;
				transition: opacity 500ms;
			}
		}

	}






	#app_nav_logo {
		position: relative;
		top: 0;
		left: 0;
		margin: 0;
		padding: 0;
		display: none;
		height: 100%;
		width: 10em;
		cursor: pointer;

		img {
			position: absolute;
			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 65%;
		}
	}

	#app_nav.inner {
		height: 4em;
		transition: height 300ms;

		#app_nav_logo {
			display: inline-block;
		}
		ul#app_nav_items li {
			transition: padding 300ms;
			padding-top: 1.7em;
		}
		ul#app_nav_items li:first-child {
			margin-left: 6em;
			//display: none;
		}

	}

	#app_nav.inner.tiny {
		height: 2.5em;

		ul#app_nav_items li {
			padding-top: 0.7em;
		}
	}




	#app_footer {
		margin: 7em 0 2em;


		ul {
			position: relative;
			width: 1000px;
			margin: 0 auto;
			padding: 1em 0 0 0;
			list-style: none;
			font: 400 0.9em 'Open Sans', sans-serif;
			text-align: center;
			border-top: 1px #bbb solid;


			li {
				display: inline-block;
				margin: 0 5em 0 0;
				cursor: pointer;
				color: #444444;
				transition: color 700ms;
			}
			li:last-child {
				margin-right: 0;
			}
			li:hover {
				color: #005da4;
			}
		}

		#app_copyright {
			margin: 3em 0 0 0;
			text-align: center;
			font: 400 0.8em 'Open Sans', sans-serif;
			color: #888888;
		}
	}



}



//******************************************************************************************
//******************************************************************************************



















@media (max-width: $smallMobileWidth) {


	#app_page {
		margin-bottom: 2em;

		i#app_menu_open_icon,
		i#app_menu_close_icon {
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			padding: 0.3em;
			z-index: 1000;
			color: #ffffff;
			text-shadow: rgba(#000000,0.2) 0 1px 5px;
			font-size: 2em;
		}

		i#app_menu_close_icon {
			display: none;
			z-index: 99999999;
		}


		#app_nav,
		#app_nav.inner {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 80%;
			overflow: hidden;
			//right: 0;
			//bottom: 0;
			//left: 0;

			opacity: 0;
			z-index: -1;
			transition: height 700ms, z-index 0ms 700ms, opacity 700ms;



			#app_nav_logo {
				display: block;
				position: absolute;
				top: 3vw;
				left: -52vw;
				width: 50vw;
				height: 18vw;
				transform: rotate(-90deg);
				transform-origin: 100% 0;

				img {
					position: relative;
					width: 100%;
				}
			}


			ul#app_nav_items {
				position: absolute;
				bottom: 50%;
				left: 50%;
				transform: translate(-50%, 50%);
				width: 100%;
				height: auto;
				padding: 0;
				margin: 0;
				text-align: right;

				li {
					display: block;
					float: none;
					position: relative;
					width: 100%;
					height: auto;
					margin: 0 auto;
					padding: 7vh 0;
					text-align: center;

					img {
						display: none;
					}
				}

				li:first-child {
					margin-left: auto;
				}
			}


		}


		#app_footer {
			display: none;
		}

	}


	#app_page.show_menu {

		position: absolute;
		width: 100%;
		height: 100%;

		i#app_menu_open_icon {
			display: none;
		}

		i#app_menu_close_icon {
			display: block;
		}

		#app_nav,
		#app_nav.inner {
			transition: height 700ms, z-index 0ms 0ms, opacity 700ms;

			z-index: 99999998;
			height: 100%;
			opacity: 1;
		}
	}

}
