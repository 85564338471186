


#partners_page {

	position: relative;
	margin-top: $innerNavHeight * 1.6;



	#partners_bg {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #ffffff url(/img/bg/bridge-backgrounds-3b.jpg) no-repeat center center;
		background-size: cover;
		z-index: -1;
	}



	#partners_main_column {
		overflow: auto;
		position: relative;
		margin: 0 auto;
		max-width: 1000px;
		font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
		letter-spacing: 0.02em;

		h1 {
			font: 900 2.3em 'Open Sans', sans-serif;
			margin: 2em 0 2em 0;
		}


		.section {
			position: relative;
			margin: 0 0 6em;
			height: 7em;

			h2 {
				position: absolute;
				top: 50%;
				font: 700 1.5em 'Open Sans', sans-serif;
				width: 35%;
				//padding-top: 2em;
				margin: 0;
				white-space: nowrap;
				text-align: right;
				transform: translate(0, -50%);

			}

			img {
				position: absolute;
				left: 35%;
				width: auto;
				height: 100%;
			}
		}

		a {
			cursor: pointer;
		}

		#clinicalPartners img {
			left: 50%;
		}

		#financialPartners img {
			left: 46%;
		}

	}


}







//******************************************************************************************
//******************************************************************************************

@media (max-width: $smallMobileWidth) {

	#partners_page {

		#partners_main_column {
			max-width: 90%;

			h1 {
				margin-top: 0;
			}


			.section {
				text-align: center;
				height: auto;

				h2 {
					position: relative;
					top: 0;
					width: 100%;
					text-align: center;
					transform: none;
					padding: 0.2em 0;

					color: #f5f5f5;
					background: #005da4;
				}

				img {
					position: relative;
					height: auto;
					width: 50%;
					margin: 1em auto 0;
				}

				//a {
				//	position: relative;
				//	text-align: center;
				//	border: 10px #0000ff solid !important;
				//}
			}


			#croPartners img {
				left: inherit;
				width: 100%;
			}

			#clinicalPartners img {
				left: inherit;
				width: 70%;
				margin-top: 2em;
			}

			#financialPartners img {
				left: inherit;
				width: 70%;
				margin-top: 2em;
			}

		}

	}
}


