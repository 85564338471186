@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-01.eot?#iefix");
src:url("/css/fonts/optima/optima-01.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-01.woff") format("woff"),url("/css/fonts/optima/optima-01.ttf") format("truetype");
font-weight: 400;
font-style: normal;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-02.eot?#iefix");
src:url("/css/fonts/optima/optima-02.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-02.woff") format("woff"),url("/css/fonts/optima/optima-02.ttf") format("truetype");
font-weight: 400;
font-style: italic;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-03.eot?#iefix");
src:url("/css/fonts/optima/optima-03.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-03.woff") format("woff"),url("/css/fonts/optima/optima-03.ttf") format("truetype");
font-weight: 500;
font-style: normal;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-04.eot?#iefix");
src:url("/css/fonts/optima/optima-04.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-04.woff") format("woff"),url("/css/fonts/optima/optima-04.ttf") format("truetype");
font-weight: 500;
font-style: italic;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-05.eot?#iefix");
src:url("/css/fonts/optima/optima-05.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-05.woff") format("woff"),url("/css/fonts/optima/optima-05.ttf") format("truetype");
font-weight: 600;
font-style: normal;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-06.eot?#iefix");
src:url("/css/fonts/optima/optima-06.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-06.woff") format("woff"),url("/css/fonts/optima/optima-06.ttf") format("truetype");
font-weight: 600;
font-style: italic;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-07.eot?#iefix");
src:url("/css/fonts/optima/optima-07.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-07.woff") format("woff"),url("/css/fonts/optima/optima-07.ttf") format("truetype");
font-weight: 700;
font-style: normal;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-08.eot?#iefix");
src:url("/css/fonts/optima/optima-08.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-08.woff") format("woff"),url("/css/fonts/optima/optima-08.ttf") format("truetype");
font-weight: 700;
font-style: italic;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-09.eot?#iefix");
src:url("/css/fonts/optima/optima-09.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-09.woff") format("woff"),url("/css/fonts/optima/optima-09.ttf") format("truetype");
font-weight: 800;
font-style: normal;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-10.eot?#iefix");
src:url("/css/fonts/optima/optima-10.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-10.woff") format("woff"),url("/css/fonts/optima/optima-10.ttf") format("truetype");
font-weight: 800;
font-style: italic;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-11.eot?#iefix");
src:url("/css/fonts/optima/optima-11.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-11.woff") format("woff"),url("/css/fonts/optima/optima-11.ttf") format("truetype");
font-weight: 900;
font-style: normal;
}
@font-face{
font-family:"optima";
src:url("/css/fonts/optima/optima-12.eot?#iefix");
src:url("/css/fonts/optima/optima-12.eot?#iefix") format("eot"),url("/css/fonts/optima/optima-12.woff") format("woff"),url("/css/fonts/optima/optima-12.ttf") format("truetype");
font-weight: 900;
font-style: italic;
}
