

#home_page {
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	//overflow: hidden;
	//overflow: hidden;
	//position: relative;
	//width: 100%;
	//height: 50%;
	//border: 10px #ff0000 solid;

	#home_bridge {
		position: relative;
		top: -2em;
		left: 0;
		width: 100%;
		height: 80vh;
		//margin-top: -1%;
		//padding: 0 0 10px 0;
		//border: 10px #0000ff solid;
		//height: 500px;
		//box-shadow: inset 20px 20px 20px 20px #ff0000;

		//background: #ffffff url(/img/bg/bridge_1_wide.jpg) no-repeat top center;
		//background-size: contain;

		#home_bridge_bg_drawing {
			background: #ffffff url(/img/bg/bridge_1_wide3_drawing.jpg) no-repeat center center;
			background-size: cover;

			position: absolute;
			opacity: 0;
			width: 100%;
			height: 100%;
			z-index: 20;
			border-bottom: 2px solid #fff;
		}

		#home_bridge_bg_real {
			background: #ffffff url(/img/bg/bridge_1_wide3.jpg) no-repeat center center;
			background-size: cover;

			position: absolute;
			opacity: 1;
			width: 100%;
			height: 100%;
			z-index: 10;
			border-bottom: 2px solid #bbb;
		}

		img#home_bridge_logo {
			//border: 10px #000000 solid;
			position: absolute;
			top: 0;
			left: 10%;
			width: 30%;
			margin-top: 10%;
			z-index: 30;
		}

		img#home_bridge_slogan {
			//border: 10px #000000 solid;
			position: absolute;
			top: 0;
			left: 18%;
			width: 22%;
			margin-top: 23%;
			opacity: 0;
			z-index: 30;
		}

	}



	#home_main_column {
		//display: none;
		opacity: 1;
		position: relative;
		//border: 1px #888 dashed;
		margin: 0 auto;
		max-width: 1000px;
		font: 400 1.0em/2em 'Open Sans', arial, tahoma, sans-serif;
		letter-spacing: 0.02em;
		//font: 400 1.5em roboto, arial, tahoma, sans-serif;

		//height: 500px;

		.axella {
			font-weight: bold;
		}

		#home_intro {
			margin: 5em 0;

			.axella {
				font-size: 150%;
			}
		}


		.home_section {
			position: relative;
			//border: 1px #0000ff solid;
			overflow: auto;
			margin: 0 0 8em 0;

			img {
				position: absolute;
				width: 100%;
				top: 50%;
				margin: 0;
				transform: translateY(-50%);
			}
		}

		.home_section_image {
			//float: left;
			position: absolute;
			width: 45%;
			height: 100%;
			//height: 500px;
			overflow: hidden;
		}

		.home_section_text {
			float: right;
			width: 50%;
		}

		#home_section_clinical {
			margin: 0 0 4em 0;
		}

		#home_section_regulatory,
		#home_section_biostatistics {

			.home_section_text {
				float: left;
			}

			.home_section_image {
				right: 0;
			}
		}


	}


}






//******************************************************************************************
//******************************************************************************************













@media (max-width: $smallMobileWidth) {

	#home_page {

		#home_main_column {
			max-width: 90%;

			h1 {
				margin-top: 2em;
			}
		}




		#home_bridge {
			top: 0;
			height: 80vh;

			img#home_bridge_logo {
				width: 70%;
				left: 10%;
				margin-top: 16%;
			}

			img#home_bridge_slogan {
				width: 55%;
				margin-top: 46%;
				left: 23%;
			}

			#home_bridge_bg_drawing {
				background-position: 20% 0;
			}

			#home_bridge_bg_real {
				background-position: 20% 0;
			}

		}

		@media (orientation: landscape) {
			#home_bridge {
				img#home_bridge_logo {
					width: 50%;
					left: 5%;
					margin-top: 7%;
				}

				img#home_bridge_slogan {
					width: 43%;
					margin-top: 28%;
					left: 14%;
				}
			}
		}

		#home_main_column {

			#home_intro {
				margin: 2em 0;
			}

			.home_section {
				//border: 10px orange solid;
			}

			.home_section_image {
				position: relative;
				width: 100%;

				img {
					position: relative;
					top: 0;
					transform: translateY(0);
				}
			}

			.home_section_text {
				float: none;
				width: 100%;
			}


		}






	}
}








