#research_page {


	position: relative;
	margin-top: $innerNavHeight * 1.6;



	#research_main_column {
		//overflow: auto;
		//display: inline-block;
		//border: 1px #0000ff solid;
		position: relative;
		margin: 0 auto;
		max-width: 1000px;
		font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
		letter-spacing: 0.02em;
		text-align: center;



		h1 {
			font: 900 2.3em 'Open Sans', sans-serif;
			margin: 5em 0 1em 0;
			text-align: left;
		}


		#research_iframe {
			width: 1000px;
			height: 770px;
		}

		#axella_embed {
			margin: 5em 0 0 0;
			width: 1000px;
			height: 1300px;
		}


	}

}






//******************************************************************************************
//******************************************************************************************

@media (max-width: $smallMobileWidth) {

	#research_page {

		#research_main_column {
			max-width: 90%;

			h1 {
				margin-top: 2em;
			}

			#research_iframe {
				width: 100%;
				height: 50vh;
			}


			#axella_embed {
				width: 100%;
				height: 50vh;
			}
		}

	}
}

