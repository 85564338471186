

#welcome_page {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;


	#welcome_text_area {
		position: absolute;
		margin: 0;
		top: 50%;
		width: 100%;
		text-align: center;
		transform: translateY(-50%);
	}
	h1 {
		margin: 0;
		font: 500 3em optima,arial,tahoma,sans-serif;
	}
	h3 {
		margin: 3em 0 0 0;
		font: 500 1.5em optima,arial,tahoma,sans-serif;
	}
}