@font-face {
  font-family: 'app-icons';
  src: url('/css/icons/app-icons.eot');
  src: url('/css/icons/app-icons.eot#iefix') format('embedded-opentype'),
       url('/css/icons/app-icons.woff2') format('woff2'),
       url('/css/icons/app-icons.woff') format('woff'),
       url('/css/icons/app-icons.ttf') format('truetype'),
       url('/css/icons/app-icons.svg#app-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'app-icons';
    src: url('/css/fonts/icon-fonts/app-icons.svg?13315548#app-icons') format('svg');
  }
}
*/





 [class^="ficon-"]:before, [class*=" ficon-"]:before {
  font-family: "app-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

//border: 1px blue solid;
//display: block;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  font-size: 120%;

  /* Uncomment for 3D effect */
//  text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}


@import 'app-icons-codes';


