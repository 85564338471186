/* fonts
------------------------------------------------------------ */
@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-01.eot?#iefix");
  src: url("/css/fonts/optima/optima-01.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-01.woff") format("woff"), url("/css/fonts/optima/optima-01.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-02.eot?#iefix");
  src: url("/css/fonts/optima/optima-02.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-02.woff") format("woff"), url("/css/fonts/optima/optima-02.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-03.eot?#iefix");
  src: url("/css/fonts/optima/optima-03.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-03.woff") format("woff"), url("/css/fonts/optima/optima-03.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-04.eot?#iefix");
  src: url("/css/fonts/optima/optima-04.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-04.woff") format("woff"), url("/css/fonts/optima/optima-04.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-05.eot?#iefix");
  src: url("/css/fonts/optima/optima-05.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-05.woff") format("woff"), url("/css/fonts/optima/optima-05.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-06.eot?#iefix");
  src: url("/css/fonts/optima/optima-06.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-06.woff") format("woff"), url("/css/fonts/optima/optima-06.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-07.eot?#iefix");
  src: url("/css/fonts/optima/optima-07.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-07.woff") format("woff"), url("/css/fonts/optima/optima-07.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-08.eot?#iefix");
  src: url("/css/fonts/optima/optima-08.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-08.woff") format("woff"), url("/css/fonts/optima/optima-08.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-09.eot?#iefix");
  src: url("/css/fonts/optima/optima-09.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-09.woff") format("woff"), url("/css/fonts/optima/optima-09.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-10.eot?#iefix");
  src: url("/css/fonts/optima/optima-10.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-10.woff") format("woff"), url("/css/fonts/optima/optima-10.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-11.eot?#iefix");
  src: url("/css/fonts/optima/optima-11.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-11.woff") format("woff"), url("/css/fonts/optima/optima-11.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-12.eot?#iefix");
  src: url("/css/fonts/optima/optima-12.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-12.woff") format("woff"), url("/css/fonts/optima/optima-12.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'app-icons';
  src: url("/css/icons/app-icons.eot");
  src: url("/css/icons/app-icons.eot#iefix") format("embedded-opentype"), url("/css/icons/app-icons.woff2") format("woff2"), url("/css/icons/app-icons.woff") format("woff"), url("/css/icons/app-icons.ttf") format("truetype"), url("/css/icons/app-icons.svg#app-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'app-icons';
    src: url('/css/fonts/icon-fonts/app-icons.svg?13315548#app-icons') format('svg');
  }
}
*/
[class^="ficon-"]:before, [class*=" ficon-"]:before {
  font-family: "app-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
  /* Uncomment for 3D effect */
}

.ficon-menu-2:before {
  content: '\e800';
}

/* '?' */
.ficon-angle-double-up:before {
  content: '\e801';
}

/* '?' */
.ficon-angle-double-down:before {
  content: '\e802';
}

/* '?' */
.ficon-cancel-1:before {
  content: '\e804';
}

/* '?' */
/* mixins
------------------------------------------------------------ */
/* templates
------------------------------------------------------------ */
body {
  background: #ffffff;
  margin: 0;
  overflow-y: scroll;
}

#app_page {
  position: relative;
  top: -1px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border-top: 1px transparent solid;
}

#app_page i#app_menu_open_icon,
#app_page i#app_menu_close_icon {
  display: none;
}

#app_page #app_nav {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.5em;
  background-color: #313A43;
  text-align: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 1000ms;
}

#app_page #app_nav.hide {
  opacity: 0;
  transition: opacity 0ms;
}

#app_page ul#app_nav_items {
  display: inline-block;
  margin: 0 0 0 -3em;
  padding: 0;
  list-style: none;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
}

#app_page ul#app_nav_items img {
  margin: -0.3em 0.6em 0 0;
  padding: 0;
  height: 2em;
  float: left;
  opacity: 0;
  transition: opacity 1500ms;
}

#app_page ul#app_nav_items li {
  display: inline-block;
  margin: 0 0 0 0.3em;
  padding: 0.6em 1em 0 0.7em;
  float: left;
  color: #ffffff;
  font: 300 0.9em 'Open Sans', sans-serif;
  letter-spacing: 0.07em;
  height: 100%;
  transition: background 1500ms;
}

#app_page ul#app_nav_items li:first-child {
  margin-left: 0;
}

#app_page ul#app_nav_items li:hover {
  background: #005da4;
  color: #ffffff;
  cursor: pointer;
  transition: background 250ms;
}

#app_page ul#app_nav_items li:hover img {
  opacity: 1;
  transition: opacity 500ms;
}

#app_page #app_nav_logo {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: none;
  height: 100%;
  width: 10em;
  cursor: pointer;
}

#app_page #app_nav_logo img {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 65%;
}

#app_page #app_nav.inner {
  height: 4em;
  transition: height 300ms;
}

#app_page #app_nav.inner #app_nav_logo {
  display: inline-block;
}

#app_page #app_nav.inner ul#app_nav_items li {
  transition: padding 300ms;
  padding-top: 1.7em;
}

#app_page #app_nav.inner ul#app_nav_items li:first-child {
  margin-left: 6em;
}

#app_page #app_nav.inner.tiny {
  height: 2.5em;
}

#app_page #app_nav.inner.tiny ul#app_nav_items li {
  padding-top: 0.7em;
}

#app_page #app_footer {
  margin: 7em 0 2em;
}

#app_page #app_footer ul {
  position: relative;
  width: 1000px;
  margin: 0 auto;
  padding: 1em 0 0 0;
  list-style: none;
  font: 400 0.9em 'Open Sans', sans-serif;
  text-align: center;
  border-top: 1px #bbb solid;
}

#app_page #app_footer ul li {
  display: inline-block;
  margin: 0 5em 0 0;
  cursor: pointer;
  color: #444444;
  transition: color 700ms;
}

#app_page #app_footer ul li:last-child {
  margin-right: 0;
}

#app_page #app_footer ul li:hover {
  color: #005da4;
}

#app_page #app_footer #app_copyright {
  margin: 3em 0 0 0;
  text-align: center;
  font: 400 0.8em 'Open Sans', sans-serif;
  color: #888888;
}

@media (max-width: 801px) {
  #app_page {
    margin-bottom: 2em;
  }
  #app_page i#app_menu_open_icon,
  #app_page i#app_menu_close_icon {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    padding: 0.3em;
    z-index: 1000;
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, 0.2) 0 1px 5px;
    font-size: 2em;
  }
  #app_page i#app_menu_close_icon {
    display: none;
    z-index: 99999999;
  }
  #app_page #app_nav,
  #app_page #app_nav.inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
    transition: height 700ms, z-index 0ms 700ms, opacity 700ms;
  }
  #app_page #app_nav #app_nav_logo,
  #app_page #app_nav.inner #app_nav_logo {
    display: block;
    position: absolute;
    top: 3vw;
    left: -52vw;
    width: 50vw;
    height: 18vw;
    transform: rotate(-90deg);
    transform-origin: 100% 0;
  }
  #app_page #app_nav #app_nav_logo img,
  #app_page #app_nav.inner #app_nav_logo img {
    position: relative;
    width: 100%;
  }
  #app_page #app_nav ul#app_nav_items,
  #app_page #app_nav.inner ul#app_nav_items {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    text-align: right;
  }
  #app_page #app_nav ul#app_nav_items li,
  #app_page #app_nav.inner ul#app_nav_items li {
    display: block;
    float: none;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 7vh 0;
    text-align: center;
  }
  #app_page #app_nav ul#app_nav_items li img,
  #app_page #app_nav.inner ul#app_nav_items li img {
    display: none;
  }
  #app_page #app_nav ul#app_nav_items li:first-child,
  #app_page #app_nav.inner ul#app_nav_items li:first-child {
    margin-left: auto;
  }
  #app_page #app_footer {
    display: none;
  }
  #app_page.show_menu {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  #app_page.show_menu i#app_menu_open_icon {
    display: none;
  }
  #app_page.show_menu i#app_menu_close_icon {
    display: block;
  }
  #app_page.show_menu #app_nav,
  #app_page.show_menu #app_nav.inner {
    transition: height 700ms, z-index 0ms 0ms, opacity 700ms;
    z-index: 99999998;
    height: 100%;
    opacity: 1;
  }
}

/* pages
------------------------------------------------------------ */
#welcome_page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#welcome_page #welcome_text_area {
  position: absolute;
  margin: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

#welcome_page h1 {
  margin: 0;
  font: 500 3em optima,arial,tahoma,sans-serif;
}

#welcome_page h3 {
  margin: 3em 0 0 0;
  font: 500 1.5em optima,arial,tahoma,sans-serif;
}

#home_page {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#home_page #home_bridge {
  position: relative;
  top: -2em;
  left: 0;
  width: 100%;
  height: 80vh;
}

#home_page #home_bridge #home_bridge_bg_drawing {
  background: #ffffff url(/img/bg/bridge_1_wide3_drawing.jpg) no-repeat center center;
  background-size: cover;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  border-bottom: 2px solid #fff;
}

#home_page #home_bridge #home_bridge_bg_real {
  background: #ffffff url(/img/bg/bridge_1_wide3.jpg) no-repeat center center;
  background-size: cover;
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-bottom: 2px solid #bbb;
}

#home_page #home_bridge img#home_bridge_logo {
  position: absolute;
  top: 0;
  left: 10%;
  width: 30%;
  margin-top: 10%;
  z-index: 30;
}

#home_page #home_bridge img#home_bridge_slogan {
  position: absolute;
  top: 0;
  left: 18%;
  width: 22%;
  margin-top: 23%;
  opacity: 0;
  z-index: 30;
}

#home_page #home_main_column {
  opacity: 1;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  font: 400 1.0em/2em 'Open Sans', arial, tahoma, sans-serif;
  letter-spacing: 0.02em;
}

#home_page #home_main_column .axella {
  font-weight: bold;
}

#home_page #home_main_column #home_intro {
  margin: 5em 0;
}

#home_page #home_main_column #home_intro .axella {
  font-size: 150%;
}

#home_page #home_main_column .home_section {
  position: relative;
  overflow: auto;
  margin: 0 0 8em 0;
}

#home_page #home_main_column .home_section img {
  position: absolute;
  width: 100%;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
}

#home_page #home_main_column .home_section_image {
  position: absolute;
  width: 45%;
  height: 100%;
  overflow: hidden;
}

#home_page #home_main_column .home_section_text {
  float: right;
  width: 50%;
}

#home_page #home_main_column #home_section_clinical {
  margin: 0 0 4em 0;
}

#home_page #home_main_column #home_section_regulatory .home_section_text,
#home_page #home_main_column #home_section_biostatistics .home_section_text {
  float: left;
}

#home_page #home_main_column #home_section_regulatory .home_section_image,
#home_page #home_main_column #home_section_biostatistics .home_section_image {
  right: 0;
}

@media (max-width: 801px) {
  #home_page #home_main_column {
    max-width: 90%;
  }
  #home_page #home_main_column h1 {
    margin-top: 2em;
  }
  #home_page #home_bridge {
    top: 0;
    height: 80vh;
  }
  #home_page #home_bridge img#home_bridge_logo {
    width: 70%;
    left: 10%;
    margin-top: 16%;
  }
  #home_page #home_bridge img#home_bridge_slogan {
    width: 55%;
    margin-top: 46%;
    left: 23%;
  }
  #home_page #home_bridge #home_bridge_bg_drawing {
    background-position: 20% 0;
  }
  #home_page #home_bridge #home_bridge_bg_real {
    background-position: 20% 0;
  }
}

@media (max-width: 801px) and (orientation: landscape) {
  #home_page #home_bridge img#home_bridge_logo {
    width: 50%;
    left: 5%;
    margin-top: 7%;
  }
  #home_page #home_bridge img#home_bridge_slogan {
    width: 43%;
    margin-top: 28%;
    left: 14%;
  }
}

@media (max-width: 801px) {
  #home_page #home_main_column #home_intro {
    margin: 2em 0;
  }
  #home_page #home_main_column .home_section_image {
    position: relative;
    width: 100%;
  }
  #home_page #home_main_column .home_section_image img {
    position: relative;
    top: 0;
    transform: translateY(0);
  }
  #home_page #home_main_column .home_section_text {
    float: none;
    width: 100%;
  }
}

#partners_page {
  position: relative;
  margin-top: 4em;
}

#partners_page #partners_bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff url(/img/bg/bridge-backgrounds-3b.jpg) no-repeat center center;
  background-size: cover;
  z-index: -1;
}

#partners_page #partners_main_column {
  overflow: auto;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
  letter-spacing: 0.02em;
}

#partners_page #partners_main_column h1 {
  font: 900 2.3em 'Open Sans', sans-serif;
  margin: 2em 0 2em 0;
}

#partners_page #partners_main_column .section {
  position: relative;
  margin: 0 0 6em;
  height: 7em;
}

#partners_page #partners_main_column .section h2 {
  position: absolute;
  top: 50%;
  font: 700 1.5em 'Open Sans', sans-serif;
  width: 35%;
  margin: 0;
  white-space: nowrap;
  text-align: right;
  transform: translate(0, -50%);
}

#partners_page #partners_main_column .section img {
  position: absolute;
  left: 35%;
  width: auto;
  height: 100%;
}

#partners_page #partners_main_column a {
  cursor: pointer;
}

#partners_page #partners_main_column #clinicalPartners img {
  left: 50%;
}

#partners_page #partners_main_column #financialPartners img {
  left: 46%;
}

@media (max-width: 801px) {
  #partners_page #partners_main_column {
    max-width: 90%;
  }
  #partners_page #partners_main_column h1 {
    margin-top: 0;
  }
  #partners_page #partners_main_column .section {
    text-align: center;
    height: auto;
  }
  #partners_page #partners_main_column .section h2 {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
    transform: none;
    padding: 0.2em 0;
    color: #f5f5f5;
    background: #005da4;
  }
  #partners_page #partners_main_column .section img {
    position: relative;
    height: auto;
    width: 50%;
    margin: 1em auto 0;
  }
  #partners_page #partners_main_column #croPartners img {
    left: inherit;
    width: 100%;
  }
  #partners_page #partners_main_column #clinicalPartners img {
    left: inherit;
    width: 70%;
    margin-top: 2em;
  }
  #partners_page #partners_main_column #financialPartners img {
    left: inherit;
    width: 70%;
    margin-top: 2em;
  }
}

#investors_page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#investors_page #investors_bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff url(/img/bg/bridge-backgrounds-4.jpg) no-repeat 20% 50%;
  background-size: cover;
}

#investors_page #investors_bg_files {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 6em 3em 3em;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: opacity 2000ms;
}

#investors_page #investors_bg_files h1 {
  font: 900 2.3em 'Open Sans', sans-serif;
  color: #000000;
  margin: 0 0 0.5em 1em;
}

#investors_page #investors_bg_files .investors_file {
  display: block;
  float: left;
  position: relative;
  text-align: center;
  font: 400 1.0em 'Open Sans', sans-serif;
  margin: 1em 2em;
  padding: 0.5em;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  color: #000000;
  transition: background 500ms, box-shadow 500ms;
}

#investors_page #investors_bg_files .investors_file img {
  width: 6em;
}

#investors_page #investors_bg_files .investors_file:hover {
  background: rgba(255, 255, 102, 0.3);
  box-shadow: rgba(255, 255, 102, 0.3) 0 0 50px;
}

#investors_page #investors_bg_files.show {
  opacity: 1;
}

#investors_page form#investors_form {
  position: absolute;
  z-index: 20;
  top: 70%;
  left: 72%;
  text-align: left;
  transform: translate(-50%, -50%);
  transition: opacity 1000ms;
}

#investors_page form#investors_form h1 {
  font: 900 2.3em 'Open Sans', sans-serif;
  color: #ffffff;
  margin: 0 0 0.5em;
}

#investors_page form#investors_form input {
  outline: none;
  padding: 0.3em 0.5em;
  font: 400 1.0em 'Open Sans', sans-serif;
  margin: 0 0 1em 0;
  transition: box-shadow 1500ms;
}

#investors_page form#investors_form input:focus {
  box-shadow: rgba(255, 255, 102, 0.6) 0 0 50px;
}

#investors_page form#investors_form button {
  margin: 0 auto;
  padding: 0.5em 1.8em;
  font: 400 0.9em 'Open Sans', sans-serif;
  cursor: pointer;
  transition: box-shadow 1500ms;
}

#investors_page form#investors_form button:focus,
#investors_page form#investors_form button:hover {
  box-shadow: rgba(255, 255, 102, 0.6) 0 0 50px;
}

#investors_page form.hide {
  opacity: 0;
}

#app_page.page_app_investors #app_footer {
  display: none;
}

@media (max-width: 801px) {
  #investors_page form#investors_form {
    top: 50%;
    left: 50%;
  }
  #investors_page #investors_bg_files {
    padding: 2em;
  }
  #investors_page #investors_bg_files h1 {
    margin: 0 0 0.5em 0;
  }
  #investors_page #investors_bg_files .investors_file {
    float: none;
    font-size: 0.7em;
    width: 100%;
    margin: 0 auto;
  }
  #investors_page #investors_bg_files .investors_file img {
    width: 15vw;
  }
}

#management_page {
  position: relative;
  margin-top: 4em;
}

#management_page #management_bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff url(/img/bg/bridge-backgrounds-3b.jpg) no-repeat center center;
  background-size: cover;
  z-index: -1;
}

#management_page #management_main_column {
  overflow: auto;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
  letter-spacing: 0.02em;
}

#management_page #management_main_column h1 {
  font: 900 2.3em 'Open Sans', sans-serif;
  margin: 5em 0 3em 0;
}

#management_page #management_main_column .management_profiles {
  overflow: auto;
}

#management_page #management_main_column .management_profile {
  float: left;
  width: 46%;
  margin: 0 0 5em 0;
}

#management_page #management_main_column .management_profile:nth-child(odd) {
  margin-right: 7%;
}

#management_page #management_main_column .management_profile_header {
  margin: 0 0 0.6em 0;
}

#management_page #management_main_column .management_profile_header_image {
  width: 9em;
  margin: 0 1.5em 0 0;
  vertical-align: bottom;
}

#management_page #management_main_column .management_profile_header_text {
  display: inline-block;
}

#management_page #management_main_column .management_profile_header_text h4 {
  margin: 0;
  font: 700 1.0em 'Open Sans', arial, tahoma, sans-serif;
}

#management_page #management_main_column .management_profile_header_text h5 {
  margin: 0;
  font: 300 0.8em 'Open Sans', arial, tahoma, sans-serif;
}

#management_page #management_main_column .management_profile_text {
  font: 400 0.9em/1.6em 'Open Sans', arial, tahoma, sans-serif;
  letter-spacing: 0.02em;
  overflow: hidden;
  max-height: 18em;
  transition: max-height 1400ms;
  border-bottom: 1px #000000 solid;
}

#management_page #management_main_column .management_profile_text p {
  width: 95%;
}

#management_page #management_main_column .management_profile_text.open {
  max-height: 70em;
}

#management_page #management_main_column .management_profile_open_button {
  text-align: center;
  cursor: pointer;
}

#management_page #management_main_column .management_profile_close_button {
  text-align: center;
  cursor: pointer;
}

@media (max-width: 801px) {
  #management_page #management_main_column {
    max-width: 90%;
  }
  #management_page #management_main_column h1 {
    margin-top: 0;
  }
  #management_page #management_main_column .management_profile {
    width: 100%;
  }
}

#contact_page {
  position: relative;
  margin-top: 4em;
}

#contact_page #contact_bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff url(/img/bg/bridge-backgrounds-3b.jpg) no-repeat center center;
  background-size: cover;
  z-index: -1;
}

#contact_page #contact_main_column {
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
  letter-spacing: 0.02em;
}

#contact_page #contact_main_column h1 {
  font: 900 2.3em 'Open Sans', sans-serif;
  margin: 5em 0 1em 0;
}

#contact_page #contact_main_column #contact_intro {
  margin: 0 0 4em;
}

#contact_page #contact_main_column form input {
  outline: none;
  padding: 0.3em 0.5em;
  font: 400 1.0em 'Open Sans', sans-serif;
  margin: 0 0 1em 0;
  border: 1px #aaa solid;
  transition: box-shadow 1500ms;
}

#contact_page #contact_main_column form textarea {
  width: 30em;
  height: 12em;
  outline: none;
  padding: 0.3em 0.5em;
  font: 400 1.0em 'Open Sans', sans-serif;
  margin: 0 0 1em 0;
  border: 1px #aaa solid;
  transition: box-shadow 1500ms;
}

#contact_page #contact_main_column form input:focus,
#contact_page #contact_main_column form textarea:focus {
  box-shadow: rgba(255, 255, 102, 0.6) 0 0 50px;
}

#contact_page #contact_main_column form button {
  margin: 0 auto;
  padding: 0.5em 1.8em;
  font: 400 0.9em 'Open Sans', sans-serif;
  cursor: pointer;
  transition: box-shadow 1500ms;
}

#contact_page #contact_main_column form button:focus,
#contact_page #contact_main_column form button:hover {
  box-shadow: rgba(255, 255, 102, 0.6) 0 0 50px;
}

@media (max-width: 801px) {
  #contact_page #contact_main_column {
    max-width: 90%;
  }
  #contact_page #contact_main_column h1 {
    margin-top: 2em;
  }
  #contact_page #contact_main_column form input {
    width: 90%;
  }
  #contact_page #contact_main_column form textarea {
    width: 90%;
  }
}

#research_page {
  position: relative;
  margin-top: 4em;
}

#research_page #research_main_column {
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
  letter-spacing: 0.02em;
  text-align: center;
}

#research_page #research_main_column h1 {
  font: 900 2.3em 'Open Sans', sans-serif;
  margin: 5em 0 1em 0;
  text-align: left;
}

#research_page #research_main_column #research_iframe {
  width: 1000px;
  height: 770px;
}

#research_page #research_main_column #axella_embed {
  margin: 5em 0 0 0;
  width: 1000px;
  height: 1300px;
}

@media (max-width: 801px) {
  #research_page #research_main_column {
    max-width: 90%;
  }
  #research_page #research_main_column h1 {
    margin-top: 2em;
  }
  #research_page #research_main_column #research_iframe {
    width: 100%;
    height: 50vh;
  }
  #research_page #research_main_column #axella_embed {
    width: 100%;
    height: 50vh;
  }
}
