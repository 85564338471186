#management_page {

	position: relative;
	margin-top: $innerNavHeight * 1.6;


	#management_bg {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #ffffff url(/img/bg/bridge-backgrounds-3b.jpg) no-repeat center center;
		background-size: cover;
		z-index: -1;
	}

	#management_main_column {
		overflow: auto;
		position: relative;
		margin: 0 auto;
		max-width: 1000px;
		font: 400 1.0em 'Open Sans', arial, tahoma, sans-serif;
		letter-spacing: 0.02em;


		h1 {
			font: 900 2.3em 'Open Sans', sans-serif;
			margin: 5em 0 3em 0;

		}

		.management_profiles {
			overflow: auto;
		}

		.management_profile {
			float: left;
			width: 46%;
			margin: 0 0 5em 0;
		}

		.management_profile:nth-child(odd) {
			margin-right: 7%;
		}

		.management_profile_header {
			margin: 0 0 0.6em 0;
		}

		.management_profile_header_image {
			width: 9em;
			margin: 0 1.5em 0 0;
			vertical-align: bottom;

			//border: 1px #888 solid;
		}

		.management_profile_header_text {
			display: inline-block;

			h4 {
				margin: 0;
				font: 700 1.0em 'Open Sans', arial, tahoma, sans-serif;
			}
			h5 {
				margin: 0;
				font: 300 0.8em 'Open Sans', arial, tahoma, sans-serif;
			}
		}

		.management_profile_text {
			font: 400 0.9em/1.6em 'Open Sans', arial, tahoma, sans-serif;
			letter-spacing: 0.02em;
			overflow: hidden;
			max-height: 18em;
			transition: max-height 1400ms;

			border-bottom: 1px #000000 solid;

			p {
				width: 95%;
			}
		}

		.management_profile_text.open {
			max-height: 70em;
		}

		.management_profile_open_button {
			text-align: center;
			cursor: pointer;
		}

		.management_profile_close_button {
			text-align: center;
			cursor: pointer;
		}

	}



}











//******************************************************************************************
//******************************************************************************************

@media (max-width: $smallMobileWidth) {

	#management_page {

		#management_main_column {
			max-width: 90%;

			h1 {
				margin-top: 0;
			}


			.management_profile {
				width: 100%;
			}

		}

	}
}


